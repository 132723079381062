.sim {
	background-repeat: no-repeat !important;
	background-size: 9px !important;
	padding-left: 14px !important;
}

.sim-1 {
	background: url(../../img/sim-1.png);
}

.sim-2 {
	background: url(../../img/sim-2.png);
}

.sim-3 {
	background: url(../../img/sim-3.png);
}

.sim-4 {
	background: url(../../img/sim-4.png);
}

.sim-5 {
	background: url(../../img/sim-5.png);
}

.sim-6 {
	background: url(../../img/sim-6.png);
}

.sim-7 {
	background: url(../../img/sim-7.png);
}

.sim-8 {
	background: url(../../img/sim-8.png);
}

.sim-null {
	background: url(../../img/sim-null.png);
}

.apn-tag {
	background-color: #000000b3;
}

.cell-mark-detail {
	color: whitesmoke;
	font-size: .3rem;
	padding: 0 .3rem;
}