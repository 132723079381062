html,
body,
#root,
.app {
    height: 100%;
    margin: 0;
    padding: 0;
}

.btn {
    border-radius: unset !important;
    color: #fff;
    font-size: .75rem !important;
}

.btn-grey {
    border-color: #a0a0a0;
    background-color: #a0a0a0;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.p0 {
    padding: 0px;
}

.mr-1 {
    margin-right: 1em;
}

.mr-2 {
    margin-right: 2em;
}

.df {
    display: flex !important;
}

.text-info {
    color: #888 !important;
}

.text-warning {
    color: #ffc107 !important;
}

.text-error {
    color: #dc3545 !important;
}

.mask-layer {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    -moz-opacity: 0.5;
    filter: alpha(opacity=50);
    position: fixed;
    z-index: 999;
    display: none;
}

.mask-layer.hide {
    display: none;
}

.mask-layer.show {
    display: block;
}

.d-loader-box {
    vertical-align: top !important;
    width: auto !important;
    text-align: center;
    padding: 0px 15px !important;
}

.d-loader-modal {
    position: absolute !important;
    top: 50% !important;
    left: 50%;
}

.btn-search:hover {
    text-decoration: none;
}

.search-box {
    padding: 5px;
    margin: 10px;
    background-color: #f7f7f7;

    label {
        font-size: .8rem;
        margin-right: .5rem;
        margin-top: inherit;
    }

    .form-control-sm {
        width: unset;
        height: calc(1.5em + .75rem + 2px);
        margin-top: auto;
        margin-right: 1rem;
    }

    .btn {
        margin-top: auto;
        height: max-content !important;
        margin: auto 2px;
    }
}

.container.user-center,
.container.contacts,
.container.message {
    font-size: .7rem;
    margin-top: 10px;
}

.page-item {
    font-size: 1rem;
}

table {

    thead,
    td,
    th {
        text-align: left;
    }
}

.custom-control-label {
    padding: .2rem;
}