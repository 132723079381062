.error-page {
    margin-top: 4rem;
    ;

    .space {
        max-height: 1px;
        min-height: 1px;
        overflow: hidden;
        margin: 12px 0;
    }

    .well {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
        color: #777 !important
    }

    .well .lithter {
        font-weight: lighter;
    }

    .well h1.smaller {
        font-size: 2rem;
    }

    .well h3.smaller {
        font-size: 1.2rem !important;
    }
}