.user-center {
    .user-info.card-group>.card {
        margin-bottom: .8rem;
    }

    .avatar img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    }

    .bind-cell:after {
        font-family: iconfont;
        content: "\e625";
        font-size: 14px;
        cursor: pointer;
        padding: 8px 5px;
    }

    .bind-cell {
        margin: 3px 5px;
        padding: 5px;
        border: dashed #807b7b 1px;
        min-width: 110px;
    }

    .bind label {
        margin: 3px 5px;
    }

    .user-info span {
        margin-right: .6rem;
        line-height: 1.3rem;
    }

    .page-item span {
        margin-right: 0;
        line-height: 1.3rem;
    }

    .card-title {
        font-size: .9rem;
    }

    .card-title i {
        margin-right: .2rem;
    }
}




// .card {
//     margin-bottom: .8rem;
// }

// .card-group>.card {
//     margin-bottom: .8rem;
// }


// h5 {
//     font-size: 1rem;
// }

// h5 .iconfont {
//     font-size: 1rem;
//     margin: 5px;
// }

// .card-body span,
// .card-body td,
// .bind label {
//     color: #888;
// }




// .logs td,
// .contacts td {
//     word-break: break-all !important;
// }




// hr {
//     height: 0;
//     box-sizing: content-box;
// }


// .admin-container .alert-danger {
//     color: #fff;
//     background-color: #ff407b;
//     border-color: #ff407b;
//     border-radius: unset;
//     position: absolute;
//     float: right;
//     right: 0;
//     z-index: 999;
// }