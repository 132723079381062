.navbar-vertical-content {
    height: 100% !important;
    min-width: 130px !important;
    background-color: #313a45;
    color: #8391a2;
    overflow-y: auto;
    padding: 0 !important;
    float: left;

    a {
        color: #8391a2;
    }

    a :hover,
    .nav-link:hover {
        color: #cedce4;
        text-decoration: none;
    }

    .sub-items {
        display: none;
        padding-left: 1rem;
        padding-top: .5rem;
    }

    .fade.show {
        display: block;
        opacity: 1;
    }

    .nav-link {
        line-height: 1.5rem;
    }

    .nav .menu-text {
        font-size: .8rem;
    }

    .nav-item i {
        margin: .2rem;
    }

    .nav-link.active,
    .sub-nav-link.active {
        color: #ffffff;
        background-color: unset !important;
    }
    
    .fade {
        transition: unset;
    }
}