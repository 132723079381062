body *,
body *:before,
body *:after {
    box-sizing: inherit;
}

[class*="loader-"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
    color: #FFF;
}

.loader-01 {
    border: .2em dotted currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-01 linear infinite;
    animation: 1s loader-01 linear infinite;
}

@-webkit-keyframes loader-01 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-01 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-02 {
    border: .2em solid transparent;
    border-left-color: currentcolor;
    border-right-color: currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-02 linear infinite;
    animation: 1s loader-02 linear infinite;
}

@-webkit-keyframes loader-02 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-02 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-03 {
    border: .2em solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    -webkit-animation: 1s loader-03 linear infinite;
    animation: 1s loader-03 linear infinite;
    position: relative;
}

@-webkit-keyframes loader-03 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-03 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-04 {
    border: 1px solid currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-04 linear infinite;
    animation: 1s loader-04 linear infinite;
    position: relative;
}

.loader-04:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -.2em;
    left: 50%;
    border: .2em solid currentcolor;
    border-radius: 50%;
}

@-webkit-keyframes loader-04 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-04 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-05 {
    border: .2em solid transparent;
    border-top-color: currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-05 linear infinite;
    animation: 1s loader-05 linear infinite;
    position: relative;
}

.loader-05:before {
    content: '';
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: -.2em;
    left: -.2em;
    border: .2em solid currentcolor;
    border-radius: 50%;
    opacity: .5;
}

@-webkit-keyframes loader-05 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-05 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-06 {
    border: .2em solid currentcolor;
    border-radius: 50%;
    -webkit-animation: loader-06 1s ease-out infinite;
    animation: loader-06 1s ease-out infinite;
}

@-webkit-keyframes loader-06 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes loader-06 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

.loader-07 {
    border: 0 solid transparent;
    border-radius: 50%;
    position: relative;
}

.loader-07:before,
.loader-07:after {
    content: '';
    border: .2em solid currentcolor;
    border-radius: 50%;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: loader-07 1s linear infinite;
    animation: loader-07 1s linear infinite;
    opacity: 0;
}

.loader-07:before {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.loader-07:after {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

@-webkit-keyframes loader-07 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes loader-07 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

.loader-08 {
    position: relative;
}

.loader-08:before,
.loader-08:after {
    content: '';
    width: inherit;
    height: inherit;
    border-radius: 50%;
    background-color: currentcolor;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: loader-08 2.0s infinite ease-in-out;
    animation: loader-08 2.0s infinite ease-in-out;
}

.loader-08:after {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes loader-08 {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes loader-08 {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.loader-09 {
    background-color: currentcolor;
    border-radius: 50%;
    -webkit-animation: loader-09 1.0s infinite ease-in-out;
    animation: loader-09 1.0s infinite ease-in-out;
}

@-webkit-keyframes loader-09 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes loader-09 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

.loader-10 {
    position: relative;
    -webkit-animation: loader-10-1 2.0s infinite linear;
    animation: loader-10-1 2.0s infinite linear;
}

.loader-10:before,
.loader-10:after {
    content: '';
    width: 0;
    height: 0;
    border: .5em solid currentcolor;
    display: block;
    position: absolute;
    border-radius: 100%;
    -webkit-animation: loader-10-2 2s infinite ease-in-out;
    animation: loader-10-2 2s infinite ease-in-out;
}

.loader-10:before {
    top: 0;
    left: 50%;
}

.loader-10:after {
    bottom: 0;
    right: 50%;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes loader-10-1 {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-10-1 {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader-10-2 {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes loader-10-2 {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.loader-11 {
    background-color: currentcolor;
    -webkit-animation: loader-11 1.2s infinite ease-in-out;
    animation: loader-11 1.2s infinite ease-in-out;
}

@-webkit-keyframes loader-11 {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@keyframes loader-11 {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

.loader-12 {
    position: relative;
}

.loader-12:before,
.loader-12:after {
    content: '';
    display: block;
    position: absolute;
    background-color: currentcolor;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 50%;
    box-shadow: -.5em 0 0 currentcolor;
    -webkit-animation: loader-12 1s linear infinite;
    animation: loader-12 1s linear infinite;
}

.loader-12:after {
    top: 50%;
    bottom: 0;
    -webkit-animation-delay: .25s;
    animation-delay: .25s;
}

@-webkit-keyframes loader-12 {

    0%,
    100% {
        box-shadow: -.5em 0 0 transparent;
        background-color: currentcolor;
    }

    50% {
        box-shadow: -.5em 0 0 currentcolor;
        background-color: transparent;
    }
}

@keyframes loader-12 {

    0%,
    100% {
        box-shadow: -.5em 0 0 transparent;
        background-color: currentcolor;
    }

    50% {
        box-shadow: -.5em 0 0 currentcolor;
        background-color: transparent;
    }
}

.loader-13:before,
.loader-13:after,
.loader-13 {
    border-radius: 50%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: loader-13 1.8s infinite ease-in-out;
    animation: loader-13 1.8s infinite ease-in-out;
}

.loader-13 {
    color: currentcolor;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    top: -1em;
}

.loader-13:before {
    right: 100%;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader-13:after {
    left: 100%;
}

.loader-13:before,
.loader-13:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
}

@-webkit-keyframes loader-13 {

    0%,
    80%,
    100% {
        box-shadow: 0 1em 0 -1em;
    }

    40% {
        box-shadow: 0 1em 0 -.2em;
    }
}

@keyframes loader-13 {

    0%,
    80%,
    100% {
        box-shadow: 0 1em 0 -1em;
    }

    40% {
        box-shadow: 0 1em 0 -.2em;
    }
}

.loader-14 {
    border-radius: 50%;
    box-shadow: 0 1em 0 -.2em currentcolor;
    position: relative;
    -webkit-animation: loader-14 0.8s ease-in-out alternate infinite;
    animation: loader-14 0.8s ease-in-out alternate infinite;
    -webkit-animation-delay: 0.32s;
    animation-delay: 0.32s;
    top: -1em;
}

.loader-14:after,
.loader-14:before {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    -webkit-animation: inherit;
    animation: inherit;
}

.loader-14:before {
    left: -1em;
    -webkit-animation-delay: 0.48s;
    animation-delay: 0.48s;
}

.loader-14:after {
    right: -1em;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
}

@-webkit-keyframes loader-14 {
    0% {
        box-shadow: 0 2em 0 -.2em currentcolor;
    }

    100% {
        box-shadow: 0 1em 0 -.2em currentcolor;
    }
}

@keyframes loader-14 {
    0% {
        box-shadow: 0 2em 0 -.2em currentcolor;
    }

    100% {
        box-shadow: 0 1em 0 -.2em currentcolor;
    }
}

.loader-15 {
    background: currentcolor;
    position: relative;
    -webkit-animation: loader-15 1s ease-in-out infinite;
    animation: loader-15 1s ease-in-out infinite;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    width: .25em;
    height: .5em;
}

.loader-15:after,
.loader-15:before {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background: inherit;
    -webkit-animation: inherit;
    animation: inherit;
}

.loader-15:before {
    right: .5em;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.loader-15:after {
    left: .5em;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

@-webkit-keyframes loader-15 {

    0%,
    100% {
        box-shadow: 0 0 0 currentcolor, 0 0 0 currentcolor;
    }

    50% {
        box-shadow: 0 -.25em 0 currentcolor, 0 .25em 0 currentcolor;
    }
}

@keyframes loader-15 {

    0%,
    100% {
        box-shadow: 0 0 0 currentcolor, 0 0 0 currentcolor;
    }

    50% {
        box-shadow: 0 -.25em 0 currentcolor, 0 .25em 0 currentcolor;
    }
}

.loader-16 {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
    -webkit-perspective: 1000px;
    perspective: 1000px;
    border-radius: 50%;
}

.loader-16:before,
.loader-16:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

.loader-16:before {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
}

.loader-16:after {
    -webkit-transform: rotateY(70deg);
    transform: rotateY(70deg);
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
        transform: translate(-50%, -50%) rotateZ(0deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
        transform: translate(-50%, -50%) rotateZ(0deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@-webkit-keyframes rotateccw {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(-360deg);
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes rotateccw {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(-360deg);
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@-webkit-keyframes spin {

    0%,
    100% {
        box-shadow: .2em 0px 0 0px currentcolor;
    }

    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }

    25% {
        box-shadow: 0 .2em 0 0px currentcolor;
    }

    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }

    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
    }

    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }

    75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
    }

    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
    }
}

@keyframes spin {

    0%,
    100% {
        box-shadow: .2em 0px 0 0px currentcolor;
    }

    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }

    25% {
        box-shadow: 0 .2em 0 0px currentcolor;
    }

    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }

    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
    }

    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }

    75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
    }

    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
    }
}

.loader-17 {
    position: relative;
    background-color: currentcolor;
    border-radius: 50%;
}

.loader-17:after,
.loader-17:before {
    content: "";
    position: absolute;
    width: .25em;
    height: .25em;
    border-radius: 50%;
    opacity: .8;
}

.loader-17:after {
    left: -.5em;
    top: -.25em;
    background-color: currentcolor;
    -webkit-transform-origin: .75em 1em;
    transform-origin: .75em 1em;
    -webkit-animation: loader-17 1s linear infinite;
    animation: loader-17 1s linear infinite;
    opacity: .6;
}

.loader-17:before {
    left: -1.25em;
    top: -.75em;
    background-color: currentcolor;
    -webkit-transform-origin: 1.5em 1em;
    transform-origin: 1.5em 1em;
    -webkit-animation: loader-17 2s linear infinite;
    animation: loader-17 2s linear infinite;
}

@-webkit-keyframes loader-17 {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0);
    }

    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0);
    }
}

@keyframes loader-17 {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0);
    }

    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0);
    }
}

.loader-18 {
    position: relative;
}

.loader-18:before,
.loader-18:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    border: .1em solid transparent;
    border-bottom-color: currentcolor;
    top: 0;
    left: 0;
    -webkit-animation: 1s loader-18 linear infinite;
    animation: 1s loader-18 linear infinite;
}

.loader-18:before {
    width: 1em;
    height: 1em;
}

.loader-18:after {
    width: .8em;
    height: .8em;
    top: .1em;
    left: .1em;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

@-webkit-keyframes loader-18 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-18 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-19 {
    border-top: .2em solid currentcolor;
    border-right: .2em solid transparent;
    -webkit-animation: loader-19 1s linear infinite;
    animation: loader-19 1s linear infinite;
    border-radius: 100%;
    position: relative;
}

@-webkit-keyframes loader-19 {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-19 {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-20 {
    color: rgb(255, 255, 0);
    border: 15px;
    width: 50px;
    height: 50px;
    border-top: .2em solid rgb(0, 255, 60);
    border-right: .2em solid transparent;
    border-left: .2em solid transparent;
    border-bottom: .2em solid currentcolor;
    -webkit-animation: loader-20 1s linear infinite;
    animation: loader-20 1s linear infinite;
    border-radius: 100%;
    position: relative;
}

@-webkit-keyframes loader-20 {
    to {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loader-20 {
    to {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}