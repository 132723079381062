.app {
    .main {
        background-color: #fafbfe !important;
        height: inherit;
        display: inline-table;
        width: 100%;      
    }
    .admin-container {
        padding-bottom: 1rem;
    }
}