   .page-info {
       .breadcrumb {
           background-color: unset !important;
       }

       .page-header {
           border-bottom: .0625rem solid #e7eaf3;
           padding-bottom: .75rem;
           margin-bottom: 1.25rem;

           i{
               padding-right: .3rem;
           }
       }
   }