.form-signin {
    max-width: 330px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;

    .form-control {
        position: relative;
        height: 47px !important;
        border-radius: 0 !important;
    }

    .form-control:focus {
        z-index: 2;
    }

    .form-group {
        margin-bottom: .2rem;
    }

    input {
        margin-bottom: -1px;
    }

    button {
        margin-top: 10px;
    }

    .iconfont {
        font-size: 22px;
    }

    .iconfont.focus {
        color: #28a745;
    }

    .input-group-text {
        border-radius: unset;
    }

    .ucenter {
        text-align: center;
        margin-top: 1rem;
        font-size: .8rem;

        a {
            text-decoration: none;
        }

        a :hover {
            text-decoration: none;
        }
    }

    .nav.nav-tabs {
        margin-bottom: 1rem;
    }

    .nav-link {
        padding: .5rem 0;
        color: black;
    }

    .nav-link:hover {
        border-bottom: #28a745 2px solid !important;
    }

    .nav-link.active {
        border-bottom: #28a745 2px solid !important;
    }

    .nav-tabs .nav-link {
        border: none;
        padding: .5rem 1rem;
        cursor: pointer;
    }
}

.form-signin,
.modal {
    .captch {
        cursor: pointer;
        position: absolute;
        right: 1px;
        z-index: 999;
        top: 1px;
    }
}

.modal {
    input {
        height: 45px;
    }
}