.wx-message {
    padding-top: 1rem;

    .wx-message-header {
        background: rgb(240, 240, 240);
        padding: 1rem 0;
        font-size: 0.8rem;


        .avatar {
            width: 70px;
            padding: 0;

            img {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                margin-left: -5px;
                margin-top: 6px;
            }
        }

        .base-info {
            width: max-content;
            padding: 0px;
        }

        .row {
            margin: 0px 5px;
            line-height: 1.3rem;
        }

        .row dd {
            margin: 0px 5px;
        }

        .row dd span {
            margin-left: 3px;
            color: rgb(144, 139, 139);
        }
    }
}