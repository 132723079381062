.contacts {

    .btn-link {
        color: blue;
        padding: 0;
    }

    .btn-link:hover {
        color: red;
        text-decoration: none;
    }

    .avatar img {
        width: 35px;
        height: 35px;
    }

    .avatar img {
        padding: inherit;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        margin-left: 0px;
    }

    .form-check-input {
        margin-left: 0.5rem;
        position: unset;
    }

    .table {
        font-size: 0.8rem;
    }

    .table td i {
        font-size: 25px;
    }

    .table th,
    .table td {
        vertical-align: middle;
        padding: 0.2rem;
    }


}

.contact-modal {
    .modal-body .avatar {
        width: 128px;
        height: 128px;
    }

    .modal-body .avatar img {
        width: 128px;
        height: 128px;
        margin-left: -10px;
        margin-top: 2rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    }
}