.topbar {
    background-color: #262626;
    color: #ffffff !important;
    padding: .5rem 1rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    height: 43px;

    .nav {
        background-color: #333333;
    }

    .logo img {
        width: 24px;
        margin-right: 5px;
        margin-top: -4px;
    }

    a {
        color: #8391a2;
        font-size: .8rem;
        padding: 0 .5rem;
    }

    a:hover {
        text-decoration: none;
        color: #cedce4;
    }

    .nav-link.active {
        color: #ffffff;
    }
}